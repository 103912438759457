import React from 'react'
import { Link } from 'react-router-dom'
import './css/Blogcta.scss'
function Blogcta() {
    return (
        <div className='blogcta' style={{ backgroundImage: `url(assets/about/cta.png)` }}>
            <div className='blogctainner'>
                <h1>Blog</h1>
                <p>ClearStone Group – the best tax consultancy company is now available as your business partner as well as finance partner to tackle all sorts of business advisory solutions, tax filing services, and business and consulting services.</p>
                <button className='blogbtn'><Link to={'/blogs'}>Load More</Link></button>
            </div>
        </div>
    )
}

export default Blogcta
