import React from "react";
import Automate_Your_Bookkeeping from "../components/Automate_Your_Bookkeeping";
import Benchmark_Your_Numbers from "../components/Benchmark_Your_Numbers";
import FinancialHealth_ts from "../components/FinancialHealth_ts";
import Floatingfhealth from "../components/Floatingfhealth";
import Floatingnav from "../components/Floatingnav";
import Red_flags from "../components/Red_flags";
import Save_Tax_Build_Wealth from "../components/Save_Tax_Build_Wealth";
import Scroller from "../components/Scroller";
import Subbanner from "../components/Subbanner";
import "./Financial_health.scss";
function Financial_health() {
  return (
    <div className="financial_health">
      <Subbanner
        img_desktop={"assets/Tax-Solution.jpg"}
        main="The Tax Tone"
        desc="The Proven Step-By-Step Roadmap For Reducing Tax And Building Wealth For Medical Professionals & Allied Health Practioners."
        btn=""
      />
      <div className="processwrapper">
        <FinancialHealth_ts />
        <Red_flags />
        <Automate_Your_Bookkeeping />
        <Benchmark_Your_Numbers />
        <Save_Tax_Build_Wealth />
        <Floatingfhealth />
        <Scroller />
      </div>
    </div>
  );
}

export default Financial_health;
