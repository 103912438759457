import React from "react";
import { Link } from "react-router-dom";
import "./css/Aboutinner.scss";
function Aboutinner({ data }) {
  return (
    <div className="aboutinner">
      <div className="aboutinnersec">
        <div className="aboutinnerdivider">
          <div className="aboutinnerleft">
            <h1>About us</h1>
            <p className="para">
              ClearStone Group is a leading provider of business and tax
              consultancy services in Australia. We provide a complete
              wide-range of business advisory solutions, tax filing services,
              business and consulting services; including project management,
              engineering, construction management, environmental consulting,
              and more.{" "}
            </p>
            <p className="para">
              We have a team of highly experienced and qualified professionals,
              who are dedicated to providing the best possible service for our
              clients. We take pride in ourselves on our commitment to
              excellence and our ability to deliver enriched quality results.{" "}
            </p>
            <p className="para">
              We are proud to have worked on some of the most iconic projects in
              Australia, and we continue to strive for effectively efficiency in
              everything we do. ClearStone Group is a trustable the tax tone
              provider company that facilitates quality services and deliver
              fast-paced results.
            </p>
            <p className="para">
              <Link to={`/contact`}>Contact us</Link> today to discuss your next
              project.
            </p>
            <p className="colorpri">
              <Link to={"/mission&vision"}>Our Mission, Vision & Values.</Link>
            </p>
          </div>
          <div className="aboutinnerright">
            <img src="assets/ShortlistedImages/h8ce1ybOFM.jpg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutinner;
