import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./Businesstax.scss";
function PayrollServices() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      console.log(res.banner);
      setBanner(res.banner);
      console.log(banner);
    });
  }, []);
  const data = {
    h1: "Payroll Services",
    p: `
         <p className='fp'>
         Payroll services helps you focus on growing your business while we take care of ensuring that your staff are paid correctly on time. We will also ensure that your company pays the employees taxes and superannuation.
        </p>`,

    arr: [
      [
        `Calculation of wages `,
        `The gross wages, taxes, net wages and superannuation will be calculated and recorded in the system.`,
        "assets/serviceicon/Accuratecalculationofwages.svg",
      ],
      [
        `Processing of wages `,
        `We will process wages weekly, fortnightly or monthly depending on the business requirements.`,
        "assets/serviceicon/Reviewofcontractorandvendorstatus.svg",
      ],
      [
        `Compliance & regulatory engagements `,
        `we ensure that your business is making timely payments for your employees which includes withholding tax and superannuation.`,
        "assets/serviceicon/Reviewofpenaltyrates.svg",
      ],
    ],

    p2: `If your company's payroll or HR department isn't in line with legal regulations, underpayment and overpayment can occur. Get your business through our payroll outsourcing to save yourself from all business employee's paychecks and audits.`,
    dot: true,
    caps: true,
  };
  return (
    <div className="businesstax">
      <Helmet>
        <title>Payroll Services – ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          content="To keep the complete employee’s financial transaction details our payroll outsourcing accommodates you to keep track of paycheck transactions related to salary, wages, overtime pay, and fringe benefits."
        />
      </Helmet>

      <Subbanner
        img_desktop={"assets/banners/EditBanner/1.jpg"}
        main="Payroll Services – Customize Employees’ Financial Transactions"
        desc="Payroll outsourcing keeps employee financial transaction details. ClearStone Group tracks hourly salary, wage, overtime, and benefit transactions."
        btn=""
      />

      <Breadcrumb service="Payroll Services" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default PayrollServices;
