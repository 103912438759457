import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./Businesstax.scss";
function Businesstaxation() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      // console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);
  const data = {
    h1: "Business Taxation",
    p: `
         <p className='fp'>
         We are here to help you understand and learn how your company is impacted by tax and how to best structure your business to make it suit your needs. 
        </p>`,

    arr: [
      [
        `Business tax planning `,
        `Taxation planning for your business so you know where you stand from a tax standpoint at year end. We provide guidance on how you can optimise your taxes by implementing strategies towards the end of the financial year. `,
        "assets/serviceicon/Corporateandcommercialtaxplanning.svg",
      ],

      [
        `Tax effective distributions `,
        `Utilizing the structures we help you optimise the tax and get better returns.  `,
        "assets/serviceicon/I2ncometaxplanning.svg",
      ],

      //   [
      //     `Superannuation `,
      //     `Assistance in annual SMSF compliance and our inhouse financial consulting team is able to provide guidance over asset management.`,
      //     "assets/serviceicon/Structuringandduediligence.svg",
      //   ],

      [
        `Year-end tax planning strategies `,
        `Year end planning is essential to ensure you know where your taxes stand and what to expect when the financial year ends. We help by providing strategies to help you get to a place where you are compliant and are fully informed of your taxable position as the year end closes.`,
        "assets/serviceicon/Yearendtaxplanningstrategies.svg",
      ],
    ],

    p2: `With each of our clients, we establish a long-lasting relationship in which we grow to become their go-to resource for a wide range of daily business challenges. `,
    p2p2: "  now.",
    caps: true,
  };
  return (
    <div className="businesstax">
      <Helmet>
        <title>Business Taxation Services – ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Enlarge your tax refunds and minimize the tax liabilities. Our business taxation services ameliorate income tax planning and advice, optimal business and tax structures, and tax audit & risk management…"
        />
      </Helmet>

      <Subbanner
        img_desktop="assets/banners/banners/sub7.png"
        main="Business Taxation Services – Customize Your Corporate Tax"
        desc="Understand how your corporate tax works and how our strategies can help you improve tax efficiencies and reduce on compliance burdens by smarter corporate structuring."
        btn=""
      />

      <Breadcrumb service="Business Taxation" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default Businesstaxation;
