import React, { useEffect, useState } from "react";
import "./css/Subbanner.scss";
function Subbanner(props) {
  // props.img_mob have mobile size banner make condition for mobile size banner
  // console.log(window.screen.width)
  let [banner, setBanner] = useState(props.img_desktop);
  useEffect(() => {
    if (window.screen.width > 640) {
      setBanner(props.img_desktop);
    } else {
      setBanner(props.img_desktop);

      // setBanner(props.img_mobile)
    }
  });

  return (
    <section
      title={props.title}
      alt={props.alt}
      className="subbanner"
      style={{ backgroundImage: `url(${banner})` }}

    >
      <div className="wrapper">
        <div className="subbannerside">
          <p className="subbannerh1">{props.main}</p>
          <p className="subbannerpara">{props.desc}</p>
          {props.btn ? (
            <button className="subbannerbtn">{props.btn}</button>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default Subbanner;
