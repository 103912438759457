import React from 'react'
import './css/Footer.scss'
import { FaTwitter, FaFacebookF, FaLinkedinIn, FaDotCircle } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { BsTwitterX } from "react-icons/bs";

function Footer() {
    return (
        <div className='footer'>
            <div className='footerinner'>
                <div className='footersec'>
                    <img src='logo.png' />
                    <p>ClearStone Group works as your business partner as well as finance partner to tackle all sorts of business advisory solutions, tax filing services, and business and consulting services at reasonable price.</p>
                    <div className='socialicon'>
                        <a href='https://twitter.com/clearstonegroup' target='_blank'>
                            <BsTwitterX className='icon' />
                        </a>
                        <a href='https://www.facebook.com/clearstonegroup' target='_blank'> <FaFacebookF className='icon' /></a>
                        <a href='https://www.linkedin.com/company/clearstonegroup/' target='_blank'> <FaLinkedinIn className='icon' /></a>

                    </div>
                </div>
                <div className='footersec1'>
                    <h2 className='footerhe'>Pages</h2>
                    <span></span>
                    <ul className='footerul'>
                        <li><Link to={'/'}><FaDotCircle className='icon' />Home</Link></li>
                        <li><Link to={'/tax-toolkit'}><FaDotCircle className='icon' />Toolkit</Link></li>
                        <li><Link to={'/about'}><FaDotCircle className='icon' />About</Link></li>

                    </ul>
                </div>
                <div className='footersec2'>
                    <h2 className='footerhe'>Others</h2>
                    <span></span>

                    <ul className='footerul'>
                        {/* <li> <Link to={'/blogs'}><FaDotCircle className='icon' />Blog</Link></li> */}
                        <li> <Link to={'/contact'}><FaDotCircle className='icon' />Contact</Link></li>
                        {/* <li> <Link to={'/career'}><FaDotCircle className='icon' />Career</Link></li> */}

                    </ul>
                </div>
                <div className='footersec3 '>
                    <h2 className='footerhe'>Get In Touch</h2>
                    <span></span>
                    <ul className='footerul'>
                        <li><a href='https://goo.gl/maps/Qze8YWpq6MZCbHSd7' target="_blank">Address : 23 5-7 Anella Ave, Castle Hill NSW 2154 </a></li>
                        <li><a href='https://goo.gl/maps/4SRyfCT1iJv9UyWP8' target="_blank">Address : Level 1, 108 Haldon St, Lakemba NSW 2195 </a></li>
                        <li><a href='https://maps.app.goo.gl/StLk3ZxaSaYz2BDV8' target="_blank">Address : 10/1A, Wongala Crescent, Beecroft, NSW, 2119 </a></li>
                        <li><a href='tel:(02) 9758 2911'>Phone : (02) 9758 2911</a></li>
                        <li><a href='mailto:info@clearstonegroup.com.au'>Email : info@clearstonegroup.com.au</a></li>

                    </ul>
                </div>

            </div>
        </div >
    )
}

export default Footer
