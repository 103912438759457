import React, { useEffect, useState } from "react";
import "./css/Mvsec.scss";
import { Link, useNavigate } from "react-router-dom";
import { IoDiamondOutline } from "react-icons/io5";

function Mvsec({ data }) {
  function createMarkup(abcd) {
    // console.log(abcd)
    return {
      __html: abcd,
    };
  }

  return (
    // <div className="mvsec">
    //   <div className="missionvission">
    //     <p className="para">
    //       <span>VISION: </span>
    //       To be a leading financial services organisation providing personalised
    //       strategic solutions.
    //     </p>
    //     <p className="para">
    //       <span>MISSION: </span>
    //       Our mission is to empower our clients in making informed financial
    //       decisions.
    //     </p>
    //   </div>

    //   <div className="Businesstaxsecinner">
    //     <h1 className="h1">{data.h1}</h1>
    //     <div className="line"></div>

    //     <div dangerouslySetInnerHTML={createMarkup(data.p)}></div>

    //     <div className="row">
    //       {data.arr.map((item, index) => {
    //         return (
    //           <div className="cols" key={index}>
    //             <div className="sec">
    //               <div className="fb">
    //                 <IoDiamondOutline className="icon" />
    //                 <p> {item[0]}</p>
    //               </div>
    //               <span className="paragragh">{item[1]}</span>
    //             </div>
    //           </div>
    //         );
    //       })}
    //     </div>
    //   </div>
    // </div>
    <div className="mvsec">
    <div className="missionvission">
      <p className="para">
        <span>VISION: </span>
        To be a leading financial services organisation providing personalised
        strategic solutions.
      </p>
      <p className="para">
        <span>MISSION: </span>
        Our mission is to empower our clients in making informed financial
        decisions.
      </p>
    </div>

    <div className="Businesstaxsecinner">
      <h1 className="h1">{data.h1}</h1>
      <div className="line"></div>

      <div dangerouslySetInnerHTML={createMarkup(data.p)}></div>

      <div className="row">
        {data.arr.map((item, index) => {
          return (
            <div className="cols" key={index}>
              <div className="sec">
                <div className="fb">
                  <IoDiamondOutline className="icon" />
                  <p> {item[0]}</p>
                </div>
                <span className="paragragh">{item[1]}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  </div>
  );
}

export default Mvsec;
