import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./Businesstax.scss";
function BookkeepingService() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);
  const data = {
    h1: "Bookkeeping Services",
    p: `
         <p className='fp'>
         Outsourced bookkeeping helps manage your business' finances. We'll keep supplier payments, customer receipts, invoices, payroll, financial reports, and Accounts Receivable. 24/7 access to virtual bookkeeping records. Our online accounting services record a business's day-to-day financial activity, process faster, boost report accuracy, and keep current data in the cloud.
        </p>`,

    arr: [
      [
        `Paying suppliers  `,
        `Assistance in batching payments so you can pay suppliers in one big file.`,
        "assets/serviceicon/Payingsuppliers.svg",
      ],

      [
        `Recording invoices `,
        `Invoicing clients for services rendered.`,
        "assets/serviceicon/Recordinginvoices.svg",
      ],

      [
        `Streamlined taxation services `,
        ` With the bookkeeping done by we have all the information in front of us. This reduces the time taken by our taxation team looking for information.`,
        "assets/serviceicon/Processingemployeespayroll.svg",
      ],

      [
        `Financial reporting  `,
        `Entails preparation of income statement, balance sheet and cash flow statement`,
        "assets/serviceicon/Financialreporting.svg",
      ],
    ],

    p2p2: ` now to ease your financial recording burden by leaving it in trusted hands.`,
    caps: true,
  };

  return (
    <div className="businesstax">
      <Helmet>
        <title>Bookkeeping Services – ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Expand your business with our outsourced bookkeeping services. We use the modern operational tools to deal with accounts receivable and payable, generate reports, data entry, and job costing."
        />
      </Helmet>
      <Subbanner
        img_desktop={"assets/banners/EditBanner/book.jpg"}
        main="Bookkeeping Services – Maintain Your Business Financial Details"
        desc="Boost your business financial details with our outsourced bookkeeping services. Modern operational systems help us manage accounts receivable and payable, provide reports, and job costing."
        btn=""
      />

      <Breadcrumb service="Bookkeeping Service" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default BookkeepingService;
