import React, { useEffect, useState } from "react";
import { getBanner } from "../components/Blogdata";
import Subbanner from "../components/Subbanner";
import Taxcalculator from "../components/Taxcalculator";
import "./Taxtoolkit.scss";

import { ImCancelCircle } from "react-icons/im";

import axios from "axios";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ScrollLock from "../ScrollLock";
import { Helmet } from "react-helmet";

function Taxtoolkit() {
  // const [banner, setBanner] = useState(false);

  // useEffect(() => {
  //     getBanner('service').then((res) => {
  //         console.log(res.banner);
  //         setBanner(res.banner);
  //     })

  // }, [])
  let toolkitdata = [
    {
      tk: "Tax Time Toolkit",
      name: "Car expenses",
      para: "If you use your own car for work purposes(this may be a car you own, lease or hire under a hire- purchase agreement), you can claim a deduction using either the cents per kilometre method or logbook method.",
      file: "assets/toolkit/Tax Time - Car expenses_DIGITAL.pdf",
    },
    {
      tk: "Tax Time Toolkit",
      name: "Clothing and Laundry",
      para: "With a few exceptions, clothing cannot be deducted as a work related expense.",
      file: "assets/toolkit/Tax Time - Clothingandlaundry.pdf",
    }, {
      tk: "Tax Time Toolkit",
      name: "Gifts and Donations",
      para: `You must have a record of the gift or donation, such as a receipt. If you receive a material benefit in return for your gift or donation to a DGR – for example, something that has a monetary value – it is considered a contribution and extra conditions apply. `,
      file: "assets/toolkit/Tax Time - Giftsanddonations.pdf",
    }, {
      tk: "Tax Time Toolkit",
      name: "Record Keeping for Work Expenses",
      para: `If you claim a deduction for work-related expenses, you must have records of those expenses. For some expenses you will also need to show your work-related use and how you calculated your claim. Your deduction can be disallowed if you’re not eligible or you don’t keep the right records.`,
      file: "assets/toolkit/Tax Time - Keeping records for work-related expenses_DIGITAL.pdf",
    }, {
      tk: "Tax Time Toolkit",
      name: "Overnight Travel Expenses",
      para: "You can claim a deduction for travel expenses if you travel and stay away from your home overnight in the course of  performing your work duties.",
      file: "assets/toolkit/Tax Time - Overnighttravelexpenses.pdf",
    }, {
      tk: "Tax Time Toolkit",
      name: "Selfeducation Expenses",
      para: "You can claim a deduction for a self-education expense if it has a sufficient connection to your current work activities",
      file: "assets/toolkit/Tax Time - Selfeducationexpenses.pdf",
    }, {
      tk: "Tax Time Toolkit",
      name: "Working from Home Deduction",
      para: "To work out your working from home deduction, you can use the fixed rate method or the actual cost method. Remember, you can only claim the work-related part of an expense.",
      file: "assets/toolkit/Tax Time - Working from home deduction.pdf",
    },
  ];
  // form data

  const [isSubmit, setIsSubmit] = useState(false);
  const [blockScroll, allowScroll] = ScrollLock();
  let [userobj, setUserObj] = useState();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });
  const initialVailue = {
    name: "",
    email: "",
    number: "",
  };

  const url = "https://admin.clearstone.simboz.com/insert_tool_data.php";

  const [formData, setFormData] = useState(initialVailue);

  const getData = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [tog, setTog] = useState(false);

  const onSubmit = (data) => {
    setError(
      "test",
      { type: "focus", message: "Double Check This" },
      { shouldFocus: true }
    );

    const { fname, email, number } = data;
    // console.log(data);
    if (fname && email && number) {
      const formaData = new FormData();
      formaData.append("name", fname);
      formaData.append("email", email);
      formaData.append("contact", number);
      axios
        .post(url, formaData)
        .then((res) => {
          Swal.fire(
            "Query Sent Successfully",
            "Thank you for contacting Us, One of our representative will get back to you shortly",
            "success"
          );
          setIsSubmit(true);
          setUserObj({
            name: fname,
            email: email,
            contact: number,
          });
          allowScroll();
          localStorage.setItem("toolkitdata", JSON.stringify(data));
        })
        .catch((error) => {
          console.log(error);
        });
      // } else {
      //   Swal.fire("Error", "Empty Field Found", "error");
    }
  };
  // const varifyCallback = (e) => {
  //   alert(e);
  //   console.log(e);
  // };

  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);

  const [tod, setTod] = useState(false);

  let checkScroll = () => {
    setUserObj(JSON.parse(localStorage.getItem("toolkitdata")));

    let userOBJ = JSON.parse(localStorage.getItem("toolkitdata"));

    if (userOBJ != null) {
      allowScroll();
    } else {
      blockScroll();
    }

    setTod(false);
  };
  useEffect(() => {
    // checkScroll();
  }, []);

  let navigate = useNavigate();

  let sendtohome = () => {
    navigate("/");
    allowScroll();
  };

  return (
    <div>
      <Helmet>
        <title>Tax Toolkit - ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
        />
      </Helmet>
      <div className="taxtoolkit">
        <Subbanner
          img_desktop="assets/banners/EditBanner/banner-1.jpg"
          main=""
          desc=""
          btn=""
        />
        <div className="texttoolkitsec">
          <h1>Tax Toolkit</h1>
          <div className="twosec">
            {toolkitdata.map((item, index) => {
              return (
                <div className="toolmapdata" key={index}>
                  <h2>
                    {" "}
                    {item.name} <span>{item.tk}</span>
                  </h2>
                  <p>{item.para}</p>
                  <a href={item.file} download>
                    Download
                  </a>
                </div>
              );
            })}
          </div>
          <Taxcalculator />
        </div>
      </div>
      {/* after api will uncomment it */}
      {/* {!userobj ? (
        <div className="popupform">
          <div className="formbody">
            <div className="close" onClick={sendtohome}>
              <ImCancelCircle />
            </div>
            <div className="popuphedi">
              <h3>To Enable Download Option Please Fill this Form</h3>
              <div className="line"></div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formfield">
                  <p>Full Name:</p>
                  <input
                    required
                    type="text"
                    onKeyUp={getData}
                    {...register("fname", {
                      required: "First name is Required",
                    })}
                  />
                </div>
                <div className="formfield">
                  <p>Email:</p>
                  <input
                    required
                    type="email"
                    onKeyUp={getData}
                    {...register("email", {
                      required: "email is Required",
                      pattern: /^\S+@\S+$/i,
                    })}
                  />
                </div>
                <div className="formfield">
                  <p>Contact Number:</p>
                  <input
                    required
                    type="text"
                    onKeyUp={getData}
                    {...register("number", {
                      required: "number is Required",
                      maxLength: 20,
                      pattern: {
                        value: /^\+?\d+/i,
                        message: "error message",
                      },
                    })}
                  />
                </div>
                <button className="contactbtn">Send message</button>
              </form>
            </div>
          </div>
        </div>
      ) : null} */}
    </div>
  );
}

export default Taxtoolkit;
