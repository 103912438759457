import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/Taxsolution.scss";

import { FaAtlassian } from "react-icons/fa";

function Taxsolution() {
  const navigate = useNavigate();
  let scrolltoid = (id) => {
    navigate("/financial_health");
    setTimeout(() => {
      // const section = document.querySelector("#FinancialHealth_ts");
      const section = document.getElementById(`${id}`);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  return (
    <div className="taxsolcont">
      <div className="datasec">
        <h2>
          Want To Get Some One-On-One Help To Implement The Tax Tone Right Away?
        </h2>
        <p>
          On the call, work 1:1 with a Specialist Accountant to map out your
          very own custom Tax Tone that allows you you to significantly reduce
          the amount of tax you pay, and tax these savings and invest them into
          building wealth.
        </p>
      </div>
      <div className="imgwrapper">
        <img src="./assets/taxsol.png" />

        <div className="pagewrapper">
          <div className="taxtone ">
            <h2>
              The Tax <span>Tone</span>
            </h2>
            <div className="line">
              <span className="blue"></span>
              <span className="orange"></span>
              <span className="md"></span>
              <span className="lgre"></span>
              <span className="gdre"></span>
            </div>
          </div>

          <div
            className="finance_health flexi"
            onClick={() => {
              scrolltoid("FinancialHealth_ts");
            }}
          >
            <p>
              <span>
                <img src="assets/home/tsol/Asset.png" />
              </span>
              Assess Your Financial Health
            </p>
          </div>

          <div
            className="red_flag flexi"
            onClick={() => {
              scrolltoid("Red_flags");
            }}
          >
            <p>
              <span>
                <img src="assets/home/tsol/Solve.png" />
              </span>
              Solve The Red Flags
            </p>
          </div>

          <div
            className="automated_bookkeeping flexi"
            onClick={() => {
              scrolltoid("Automate_Your_Bookkeeping");
            }}
          >
            <p>
              <span>
                <img src="assets/home/tsol/Create-Automated.png" />
              </span>
              Automate Your Bookkeeping
            </p>
          </div>

          <div
            className="industry_standerds flexi"
            onClick={() => {
              scrolltoid("Benchmark_Your_Numbers");
            }}
          >
            <p className="ali">
              <span>
                <img src="assets/home/tsol/Benchmark.png" />
              </span>
              Benchmark Your Numbers Against Industry Standards
            </p>
          </div>

          <div
            className="built_wealth flexi"
            onClick={() => {
              scrolltoid("Save_Tax_Build_Wealth");
            }}
          >
            <p>
              <span>
                <img src="assets/home/tsol/Save-tax.png" />
              </span>
              Save Tax & Build Wealth
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Taxsolution;
