import React from "react";
import "./css/Certification.scss";
function Certification({ sub, main, desc, btn }) {
  return (
    <div className="Certification">
      <div className="Certificationinner">
        <div className="certificateleft">
          <p className="certificateh3">{sub}</p>
          <p className="certificateh1">{main}</p>
          <p className="certificatepara">{desc}</p>
          {btn ? <button className="certificatebtn">{btn}</button> : null}
        </div>
        <div className="certificateright">
          <img src="assets/about/logos/logo/ca.png" />
          <img src="assets/about/logos/logo/cpa.png" />
          {/* <img src='assets/about/logos/logo/tti.png' /> */}
          <img src="assets/about/logos/logo/sage.png" />
          <img src="assets/about/logos/logo/zero.jpg" />
        </div>
      </div>
    </div>
  );
}

export default Certification;
