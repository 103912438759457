import React from "react";
import "./App.css";
import Home from "./pages/Home";
import About from "./pages/About";
import Nav from "./components/Nav.js";
import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Services from "./pages/Services";
import Bloglist from "./pages/Bloglist";
import Contact from "./pages/Contact";
import Blogdetail from "./pages/Blogdetail";
import Career from "./pages/Career";
import Businesstax from "./pages/Businesstax";
import PersonalTax from "./pages/PersonalTax";
import Consultingservices from "./pages/Consultingservices";
import Taxtoolkit from "./pages/Taxtoolkit";
import BusinessConsulting from "./pages/BusinessConsulting";
import FinancialConsulting from "./pages/FinancialConsulting";
import Businesstaxation from "./pages/Businesstaxation";
import Individualtaxation from "./pages/Individualtaxation";
import Bookkeeping from "./pages/Bookkeeping";
import BookkeepingService from "./pages/BookkeepingService";
import PayrollServices from "./pages/PayrollServices";
import Missionvission from "./pages/Missionvission";
import Financial_health from "./pages/Financial_health";

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Nav />
        <Routes>
          <Route path="/" exact element={<Home />} />
          {/* <Route path='/' exact element={<Services />} /> */}
          {/* main */}
          <Route path="/business-advisory" exact element={<Businesstax />} />
          {/* sub */}
          <Route
            path="/business-consulting"
            exact
            element={<BusinessConsulting />}
          />
          <Route
            path="/financial-consulting"
            exact
            element={<FinancialConsulting />}
          />

          {/* main */}
          <Route path="/tax-advisory" exact element={<PersonalTax />} />
          {/* sub */}
          <Route
            path="/business-taxation"
            exact
            element={<Businesstaxation />}
          />
          <Route
            path="/individual-taxation"
            exact
            element={<Individualtaxation />}
          />

          {/* main */}
          <Route
            path="/bookkeeping-and-payroll-service"
            exact
            element={<Bookkeeping />}
          />
          {/* sub */}
          <Route
            path="/bookkeeping-service"
            exact
            element={<BookkeepingService />}
          />
          <Route path="/payroll-services" exact element={<PayrollServices />} />
          <Route path="/mission&vision" exact element={<Missionvission />} />

          <Route path="/about" exact element={<About />} />
          <Route path="/blogs" exact element={<Bloglist />} />
          <Route path="/contact" exact element={<Contact />} />
          {/* career hide  */}
          {/* <Route path='/career' exact element={<Career />} /> */}
          <Route path="/tax-toolkit" exact element={<Taxtoolkit />} />
          <Route path="/blog/:slug" exact element={<Blogdetail />} />

          {/* <Route path="/blog/:slug" exact element={<Blogsec />} /> */}

          {/* procss / */}

          <Route path="/financial_health" element={<Financial_health />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
