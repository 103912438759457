import React from "react";
import "./css/Banner.scss";
import { Link } from "react-router-dom";

function Banner(props) {
  return (
    <section
      title={props.title}
      alt={props.alt}
      className="banner"
      style={{ backgroundImage: `url(${props.img})` }}
    >
      <div className="bannerside">
        <p className="bannerh3">{props.sub}</p>
        <p className="bannerh1">{props.main}</p>
        <p className="bannerpara">{props.desc}</p>
        {props.btn ? (
          <Link to="/business-advisory">
            <button className="bannerbtn">{props.btn}</button>
          </Link>
        ) : null}
      </div>
    </section>
  );
}

export default Banner;
