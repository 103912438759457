// const cat= ['web','social','seo','mobile','marketing','digital',]
import Axios from 'axios';
const api =  'https://admin.clearstone.simboz.com/api/';
export const getBlogs = new Promise( function(resolve, reject){
  Axios.get(`${api}get-blogs`).
  then((res) => {
      // setBlogs(res.data);
      resolve(res.data);


  }).catch((err) => {
      // console.log(err);
      reject(err);

  })
})

export const getrecent = new Promise( function(resolve, reject){
  Axios.get(`${api}get-blogs?is_recent=true`).
  then((res) => {
      // setBlogs(res.data);
      resolve(res.data);


  }).catch((err) => {
      // console.log(err);
      reject(err);

  })
})

export const BlogDetail = (slug) => new Promise( function(resolve, reject){
  Axios.get(`${api}get-blog/${slug}`).
  then((res) => {
      // setBlogs(res.data);
      resolve(res.data);


  }).catch((err) => {
      // console.log(err);
      reject(err);

  })
})

export const getBanner = (page) => new Promise( function(resolve, reject){
  Axios.get(`${api}get-banner/${page}`).
  then((res) => {
      // setBlogs(res.data);
      resolve(res.data);


  }).catch((err) => {
      // console.log(err);
      reject(err);

  })
})




