import React, { useState } from "react";
import "./css/TestimonialSec.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import test1 from "../assets/testim/test1.jpg";
import test2 from "../assets/testim/test2.jpg";
import test3 from "../assets/testim/test3.jpg";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

function TestimonialSec() {
  let [rm, setRm] = useState("");
  console.log(rm);
  return (
    <div className="testimonialsec">
      <div className="testisec">
        <div className="testleftsec">
          <h3>Testimonials</h3>
          <h1>Our Customers Love Us</h1>
          <p>
            Wherever you are stuck related to your business and financial issues
            – look no further, ClearStone Group – the best tax consultancy
            company, as your Finance Partner plus Business Partner has got your
            back and will provide you best strategies to deal with all sorts of
            circumstances you are facing and even will guide you of your future
            hurdles for certainty.
          </p>
        </div>
        <div className="testrightsec">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            centerMode
            centerSlidePercentage={101}
            showThumbs={false}
          >
            <div className="box">
              <p className="name">Mel G</p>
              <p>
                <FaQuoteLeft className="lquote" />
                {rm == "Mel" ? (
                  <span>
                    What a fantastic professional talented team! Each one of
                    them have always gone above and beyond to help me with my
                    Taxes/Financial planning. They give the best advise and are
                    always looking after my best interest. I have been their
                    client for over 5 years now and every year there is
                    consistency in their awesome service. I trust in them
                    wholeheartedly and would ABSOLUTELY recommend them to
                    anyone!
                    <span
                      className="marg"
                      onClick={() => {
                        setRm("");
                      }}
                    >
                      Read Less...
                    </span>
                  </span>
                ) : (
                  <span>
                    What a fantastic professional talented team! Each one of
                    them have always gone above and beyond to help me with my
                    Taxes/Financial planning. They give the best advise and are
                    always looking after my best interest.
                    <span
                      className="marg"
                      onClick={() => {
                        setRm("Mel");
                      }}
                    >
                      Read more...
                    </span>
                  </span>
                )}
                <FaQuoteRight className="quote" />
              </p>
            </div>

            <div className="box">
              <p className="name">Michael Mirarchi</p>
              <p>
                <FaQuoteLeft className="lquote" />

                <span>
                  Been with Clearstone Group for 2 years. Highly recommended,
                  Best in the Business.
                </span>
                <FaQuoteRight className="quote" />
              </p>
            </div>

            <div className="box">
              <p className="name">Caroline A</p>
              <p>
                <FaQuoteLeft className="lquote" />
                <span>
                  Highly recommended!! ClearStone Group has been my go to
                  accounting firm for several years and i wouldn't go anywhere
                  else. I have recommended to all my family amd friends amd will
                  continue to recommended.
                </span>
                <FaQuoteRight className="quote" />
              </p>
            </div>

            <div className="box">
              <p className="name">3d Design Studios pty ltd</p>
              <p>
                <FaQuoteLeft className="lquote" />

                <span>
                  Pramesh and the team are highly professional, trustworthy and
                  knowledgeable. Would have no hesitation in recommending them.
                </span>

                <FaQuoteRight className="quote" />
              </p>
            </div>

            <div className="box">
              <p className="name">Kylie Donovan</p>
              <p>
                <FaQuoteLeft className="lquote" />
                {rm == "Kylie" ? (
                  <span>
                    I can not recommend Clearstone Group accounting enough. My
                    husband and I moved to Clearstone Group from a previous
                    accountant about 18 months ago and I wish we had done so
                    sooner.
                    <br /> They have helped us greatly with both personal and
                    business finances and we are very grateful to have such
                    amazing accountants who go over and beyond for their
                    clients.
                    <span
                      className="marg"
                      onClick={() => {
                        setRm("");
                      }}
                    >
                      Read Less...
                    </span>
                  </span>
                ) : (
                  <span>
                    I can not recommend Clearstone Group accounting enough. My
                    husband and I moved to Clearstone Group from a previous
                    accountant about 18 months ago and I wish we had done so
                    sooner.
                    <span
                      className="marg"
                      onClick={() => {
                        setRm("Kylie");
                      }}
                    >
                      Read more...
                    </span>
                  </span>
                )}
                <FaQuoteRight className="quote" />
              </p>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default TestimonialSec;
