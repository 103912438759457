import React, { useEffect, useState } from "react";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./PersonalTax.scss";
import { Helmet } from "react-helmet";
function PersonalTax() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);
  const data = {
    h1: "Tax Advisory",
    p: `
         <p className='fp'>
         Our tax advisory services enable you to optimize business and tax structures, take advantage of tax benefits and manage risks. Our tax advisory services also encourage client education of corporate tax and structuring as well as individual taxation so that they are fully informed when making business decisions and submitting tax returns.
        </p>`,

    arr: [
      [
        // `Corporate and commercial tax planning `,
        `Business planning `,
        `Taxation planning for your business so you know where you stand from a tax standpoint at year end. We provide guidance on how you can optimise your taxes by implementing strategies towards the end of the financial year.`,
        "assets/serviceicon/Corporateandcommercialtaxplanning.svg",
      ],

      [
        `Income tax planning and advice `,
        `Utilizing the benefits of each corporate structure to get company optimise the return to the shareholders in the most tax efficient manner`,
        "assets/serviceicon/Incometaxplanningandadvice.svg",
      ],

      [
        `Advice on the optimal business and tax structures`,
        `Providing you with corporate structures that works best for you and advising how each corporate structure will provide tax benefits and asset protection.`,
        "assets/serviceicon/Adviceontheoptimalbusinessandtaxstructures.svg",
      ],

      [
        `Tax audit & risk management `,
        `Supporting your business during Tax Audits.`,
        "assets/serviceicon/Taxauditriskmanagement.svg",
      ],
    ],

    p2: `If these services are of interest to you, please feel free to `,
    dot: true,
    caps: false,
  };
  return (
    <div className="personaltax">
      <Helmet>
        <title>Tax Advisory Services – Control Your Over Taxes Now</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Enlarge your tax refunds and minimize the tax liabilities; Our business taxation services ameliorate income tax planning and advice, optimal business and tax structures, and tax audit & risk management…"
        />
      </Helmet>
      <Subbanner
        img_desktop="assets/banners/banners/sub1.png"
        main="Tax Advisory Services – Control Your Taxes Now"
        desc="Our tax advisory services work to support your business from a compliance standpoint and a corporate structuring standpoint to improve tax efficiencies throughout your group."
        btn=""
      />

      <Breadcrumb service="Tax Advisory" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default PersonalTax;
