import React, { useEffect } from "react";
import Mvsec from "../components/Mvsec";
import Subbanner from "../components/Subbanner";
import { useNavigate } from "react-router-dom";

import "./Missionvission.scss";
import { Helmet } from "react-helmet";
function Missionvission() {
  const data2 = {
    h1: "Values With A Strong Foundation",
    arr: [
      [
        `Assurance `,
        ` We always work in your best interests`,
        "assets/serviceicon/Accuratecalculationofwages.svg",
      ],
      [
        `Trust and Integrity`,
        ` #1 in our playbook.`,
        "assets/serviceicon/Reviewofcontractorandvendorstatus.svg",
      ],
      [
        `Partnership  `,
        ` Growth never stops when we work together.`,
        "assets/serviceicon/Reviewofpenaltyrates.svg",
      ],
      [
        `Contribute   `,
        ` We play our role in the community.`,
        "assets/serviceicon/Reviewofpenaltyrates.svg",
      ],
      [
        `Clear and Prompt  `,
        `  We get to the point, and we do it on time.`,
        "assets/serviceicon/Reviewofpenaltyrates.svg",
      ],
    ],
  };

  return (
    <div className="Missionvission">
      <Helmet>
        <title>Mission & Vision - ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          // content="To keep the complete employee’s financial transaction details, our payroll outsourcing keeps track of employees' salary, wages, overtime compensation, and fringe benefits."
        />
      </Helmet>
      <Subbanner
        img_desktop="assets/banners/EditBanner/2121.jpg"
        main="Mission & Vision"
        desc=""
        btn=""
      />
      <Mvsec data={data2} />
    </div>
  );
}

export default Missionvission;
