import React from "react";
import "./css/Aboutsection.scss";
import aboutung from "../assets/home/aboutusimg.jpg";
function Aboutsection() {
  return (
    <div className="homeaboutsec">
      <div className="homeaboutinner">
        <div className="homeaboutleft">
          {/* <div className="wrap"></div> */}
          <img
            className="homeaboutlefte"
            src="assets/ShortlistedImages/Wlh4TNsOfH (1).jpg"
          />
        </div>
        <div className="innerupper">
          <div className="homeaboutright">
            <h4>Trust With Our Best Accountants</h4>
            <h1>Finance & Business Partner </h1>
            <p className="fp">
              {" "}
              We believe that trust is the most valued attribute in any business
              partnership. We want you to be able to place your trust in our
              experienced accountants so that you can redirect your efforts
              towards primary operations. Whether it is tax consulting,
              bookkeeping, or payroll we provide solutions to your financial
              problems and assist in mitigating risk.
            </p>
            <p className="fp">
              Choosing to partner with ClearStone Group entails:
            </p>
            <ul>
              <li className="li">
                Business Advisory: Help your business grow
                <ul>
                  <li>
                    Business Consulting: Provide clients with business strategy
                    and support{" "}
                  </li>
                  <li>
                    Financial Consulting: Provide clients with Financial
                    budgets, projections and analysis
                  </li>
                </ul>
              </li>
              <li className="li">
                Tax Advisory: Guidance and solutions above the standard tax and
                accounting
                <ul>
                  <li>Business Taxation: Business Tax Advisory and Planning</li>
                  <li>
                    Individual Taxation: Individual Tax Advisory and Planning
                  </li>
                </ul>
              </li>
              <li className="li">
                Bookkeeping and Payroll: Control your financial audits and risk
                managements
                <ul>
                  <li>
                    Bookkeeping: Manage your company’s day to day bookkeeping
                  </li>
                  <li>
                    Payroll and others: Manage your payroll and other accounting
                    functions
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutsection;
