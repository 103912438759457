import React from 'react'
import './css/Cta.scss'
import { Link } from 'react-router-dom'
function Cta({ main, sub, desc, btn, image }) {
    return (
        <div className='cta'
            style={{ backgroundImage: `url(${image})` }}
        >
            <div className='overlay'>
                <h1><span>{main}</span> {sub}</h1>
                <p>{desc}</p>
                {btn ? <button className='aboutbtn'><Link to={'/contact'}>{btn}</Link></button> : null}
            </div>

        </div>
    )
}

export default Cta
