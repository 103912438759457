import React, { useState, useEffect } from "react";
import "./css/Scroller.scss";
import { FaAngleUp } from "react-icons/fa";

function Scroller() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", (e) => {
    window.scrollY > 100
      ? (document.getElementById("radial_scroll").style.display = "flex")
      : (document.getElementById("radial_scroll").style.display = "none");
  });

  return (
    <div
      className="radial_scroll"
      id="radial_scroll"
      onClick={() => {
        scrollToTop();
      }}
    >
      <FaAngleUp className="icon" />
    </div>
  );
}

export default Scroller;
