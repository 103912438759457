import React from "react";
import "./css/FinancialHealth_ts.scss";
import Processheading from "./Processheading";
function FinancialHealth_ts() {
  return (
    <div className="FinancialHealth_ts" id="FinancialHealth_ts">
      <Processheading pnum="1" heading=" Assess Your Financial Health" />
      <div className="innerFinancialhealth">
        <h2 className="h2">
          The first step to building wealth is to do a full audit of your
          financial health.
        </h2>

        <h2 className="h3">
          This means assessing critical information such as:
        </h2>

        <div className="spsec">
          <p>
            <span>•</span> How much you have earned in the past financial year?
          </p>
          <p>
            <span>•</span> How many credit cards you have and how much interest
            you are paying on each credit card?
          </p>
          <p>
            <span>•</span> What superannuation fund you are with and how much
            super you are contributing each month?
          </p>
          <p>
            <span>•</span> If you have car loans, personal loans or a mortgage
            and what is the interest rates on each?
          </p>
          <p>
            <span>•</span> How is your business is currently structured?
          </p>
          <p>
            <span>•</span> Do you have any late or overdue tax returns?
          </p>
          <p>
            <span>•</span> Auditing your previous tax returns to see if any
            mistakes have been made and also if you're previous accountant has
            been fully leveraging tax deductions.
          </p>
          <p>
            <span>•</span> What are your financial goals?
          </p>
        </div>

        <div className="multipara">
          <p>
            After your meeting, your Specialist Accountant will go away and
            thoroughly analyse all of the data and information you have given
            them to asses your financial health.
          </p>
          <p>
            They will then come come up with a customised journey that includes
            both short term AND long term strategies.
          </p>
        </div>
      </div>
    </div>
  );
}

export default FinancialHealth_ts;
