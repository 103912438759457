import React from "react";
import "./css/Automate_Your_Bookkeeping.scss";
import Processheading from "./Processheading";
function Automate_Your_Bookkeeping() {
  return (
    <div className="Automate_Your_Bookkeeping" id="Automate_Your_Bookkeeping">
      <Processheading pnum="3" heading="Automate Your Bookkeeping" />
      <div className="innerFinancialhealth">
        <div className="spsec">
          <p>
            <span>•</span> Once we have eliminated any 'Red Flags ' in your
            finances.
          </p>
          <p>
            <span>•</span> The next step is to automate and systemize your
            bookkeeping so that it runs like clockwork.
          </p>
          <p>
            <span>•</span> This means that you ' re finances should be up to
            date every week and you should always know EXACTLY where you stand
            financially.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Automate_Your_Bookkeeping;
