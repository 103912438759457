import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/Floatingfhealth.scss";
import { FaUserTie, FaAlipay, FaBitcoin } from "react-icons/fa";

function Floatingfhealth() {
  let [val, setVal] = useState(true);

  useEffect(() => {
    if (window.screen.width < 1200) {
      setVal(false);
    }
  }, []);

  const navigate = useNavigate();
  let scrolltoid = (id) => {
    navigate("/financial_health");
    setTimeout(() => {
      // const section = document.querySelector("#FinancialHealth_ts");
      const section = document.getElementById(`${id}`);
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 100);
  };

  let vali = window.screen.width > 500 ? 500 : 150;

  window.addEventListener("scroll", function () {
    var element = document.getElementById("Floatingfhealthid");
    var currentPosition =
      window.pageYOffset || document.documentElement.scrollTop;
    if (currentPosition > vali) {
      element.style.position = "fixed";
      element.style.top = "50% ";
      element.style.transform = "translateY(-50%) ";
    } else {
      element.style.position = "absolute";
      element.style.top = "400px ";
    }
  });
  return (
    <div
      className={
        val ? "Floatingfhealth" : "Floatingfhealth Floatingfhealthhide"
      }
      id="Floatingfhealthid"
    >
      <div className="servicewrapper">
        <h1>The Tax Tone</h1>
        <div className="line"></div>
        <ul className="services">
          <li
            onClick={() => {
              scrolltoid("FinancialHealth_ts");
            }}
          >
            1 - Assess Your Financial Health
          </li>
          <li
            onClick={() => {
              scrolltoid("Red_flags");
            }}
          >
            2 - Solve The Red Flags
          </li>
          <li
            onClick={() => {
              scrolltoid("Automate_Your_Bookkeeping");
            }}
          >
            3 - Automate Your Bookkeeping
          </li>
          <li
            onClick={() => {
              scrolltoid("Benchmark_Your_Numbers");
            }}
          >
            4 - Benchmark Your Numbers Against Industry Standards
          </li>
          <li
            onClick={() => {
              scrolltoid("Save_Tax_Build_Wealth");
            }}
          >
            5 - Save Tax & Build Wealth
          </li>
        </ul>
        <div
          onClick={() => {
            setVal(!val);
          }}
          className={val ? "toogle" : "toogle1"}
        >
          <p>{val ? "<" : ">"}</p>
        </div>
      </div>
    </div>
  );
}

export default Floatingfhealth;
