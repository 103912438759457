import React from "react";
import "./css/Benchmark_Your_Numbers.scss";
import Processheading from "./Processheading";
function Benchmark_Your_Numbers() {
  return (
    <div className="Benchmark_Your_Numbers" id="Benchmark_Your_Numbers">
      <Processheading
        pnum="4"
        heading="Benchmark Your Numbers Against Industry Standards"
      />
      <div className="innerFinancialhealth">
        <div className="multipara">
          <p>
            Once your we have automated systems in place to track your
            finances.
          </p>
          <p>
            It' s really important to bench your performance and stats againsts
            other Medical Professionals in the same industry
          </p>
          <p>
            These insights allow us to discuss ways to improve your business
            performance and leverage opportunities for growth.
          </p>
        </div>

        <h2 className="h3">Some of the insights we will provide include:</h2>
        <div className="spsec">
          <p>
            <span> Revenue comparison:</span> how much should you be
            earning/charging compared to other Medical Professionals in your
            Industry?
          </p>
          <p>
            <span>Profitability:</span> how profitable are you compared to
            similar Medical Professionals in the industry? Are your overheads
            higher or lower than the industry average?
          </p>
          <p>
            <span>Productivity analysis:</span> are you operating at an optimal
            rate compare to other Medical Professionals in the industry? Are you
            leveraging your equipment and assets appropriately?
          </p>
        </div>
      </div>
    </div>
  );
}

export default Benchmark_Your_Numbers;
