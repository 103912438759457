import React, { useEffect } from "react";
import "./Bloglist.scss";
import { getBanner, getBlogs, getrecent } from "../components/Blogdata.js";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import Subbanner from "../components/Subbanner";
import { useState } from "react";

function Bloglist() {
  const [blogdata, setBlogdata] = useState([]);
  const [blogCats, setBlogCats] = useState([]);

  const [recentdata, setRecentdata] = useState([]);
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    // console.log("times");
    getBlogs.then((res) => {
      setBlogdata(res.blogs);

      setBlogCats(res.cats);
    });

    getrecent.then((res) => {
      setRecentdata(res.blogs);
    });

    getBanner("blog").then((res) => {
      console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);

  return (
    <div className="Bloglist">
      <Subbanner
        img_desktop={
          banner
            ? banner.dekstop_img
            : "./assets/banners/EditBanner/banner-1.jpg"
        }
        main="News & Feeds"
        desc=""
        btn=""
      />
      <div className="heading">
        <h1>Blogs</h1>
        <div className="line"></div>
      </div>
      <div className="Bloglistinner">
        <div className="leftcol">
          {blogdata.map((item, index) => {
            // if (index < 4) {

            return (
              <div className="blogboxarea" key={index}>
                <img src={item?.thumbnail} />
                <p className="cat">{item.category}</p>
                <h1>{item?.title}</h1>
                <p> {item?.summary.substring(0, 200)}.....</p>
                <button className="btn">Read More</button>
              </div>
            );
            // }
          })}
        </div>
        <div className="rightcol">
          <div className="firstcol">
            <h1>Recent Blogs</h1>
            {recentdata.map((item, index) => {
              return (
                <div className="mapdata" key={index}>
                  <img src={item?.thumbnail} />
                  <p>{item?.title}</p>
                </div>
              );
            })}
          </div>
          {blogCats.length > 0 && (
            <div className="seccol">
              <h1>Categories</h1>
              {/* {Blogdata.map((item, index) => {
                            return (
                                <div className='cat' key={index}>
                                    <p>{item.cat}</p>
                                </div>
                            )
                        })} */}

              <ul>
                {blogCats.map((item, index) => {
                  return (
                    <>
                      <li>{item.category}</li>
                    </>
                  );
                })}
              </ul>
            </div>
          )}
          <div
            className="thirdcol"
            style={{ backgroundImage: `url(assets/1.jpg)` }}
          >
            <div className="thirdinner">
              <h1>Have Any Question?</h1>
              <p>
                Examine keenly both differences “Custom Website vs. WordPress
                Website.”
              </p>
              <p className="flexi">
                <FaPhoneAlt className="icons" />
                <a href="tel:(02) 9758 2911">Phone : (02) 9758 2911</a>{" "}
              </p>
              <p className="flexi">
                <FaEnvelope className="icons" />
                <a href="mailto:info@clearstonegroup.com.au">
                  Email : info@clearstonegroup.com.au
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bloglist;
