import React from "react";
import "./css/Processheading.scss";
function Processheading({ pnum, heading }) {
  return (
    <div className="Processheading">
      <h2>
        <span>{pnum}</span>
        {heading}
      </h2>
    </div>
  );
}

export default Processheading;
