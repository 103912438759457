import React from "react";
import "./css/Save_Tax_Build_Wealth.scss";
import Processheading from "./Processheading";
function Save_Tax_Build_Wealth() {
  return (
    <div className="Save_Tax_Build_Wealth" id="Save_Tax_Build_Wealth">
      <Processheading pnum="5" heading="Save Tax & Build Wealth" />
      <div className="innerFinancialhealth">
        <div className="multipara">
          <p>When it comes to preparing your tax return.</p>
          <p>
            There are many tax strategies that we can implement to significantly
            reduce the amount of tax you pay.
          </p>
          <p>So you have more money to spend, save, and invest.</p>
        </div>

        <h2 className="h3">We can help you:</h2>

        <div className="spsec">
          <p>
            <span>Implement tax-effective investment strategies</span>Grow your
            personal wealth exponentially by redirecting your tax dollars from
            the taxman ’ s pockets into longer-term investments that offer
            impressive tax savings, such as residential property, business, and
            shares.
          </p>

          <p>
            <span>Increase your take-home pay</span>Save thousands of dollars in
            taxes each year by packaging your salary to pay for your car lease,
            super, laptops, and university fees with pre-tax dollars.
          </p>

          <p>
            <span>Utilise powerful tax structures</span>Significantly reduce
            income and capital gains taxes on investments by purchasing or
            transferring assets into tax effective structures such as family
            trusts, property trusts, companies, and self-managed super funds.
          </p>

          <p>
            <span>Structure your home and investment loans</span>Implement
            tax-effective loan structures and strategies that can help you turn
            non-tax-deductible debt into tax-deductible debt, so you can pay
            debts off more easily and in a fraction of the time.
          </p>
          <p>
            <span>Boost the tax effectiveness of your investments</span>Increase
            after-tax returns for your investments by implementing tax
            strategies that help you keep more of your wealth and investment
            income. Then funnel that income into even more wealth-building
            investments.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Save_Tax_Build_Wealth;
