import React, { useEffect, useState } from "react";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./Businesstax.scss";
import { Helmet } from "react-helmet";
function BusinessConsulting() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      // console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);

  const data = {
    h1: "Business Consulting",
    p: `
         <p className='fp'>
         Our business consulting services aims to achieve audit readiness, sustainability, streamlined operations, and long-term solutions to buy, merge, and divest businesses. Some services we provide under business consulting include: 
        </p>`,

    arr: [
      [
        `Virtual CFO `,
        ` Maintenance of accurate financial data sources, preparation of periodic financial/management reports and provision of insights of the same.`,
        "assets/serviceicon/VirtualCFO.svg",
      ],
      [
        `Business Partner `,
        `We aim to act as an extension of your business and provide insights to your business strategy rather than an outsourced party.`,
        "assets/serviceicon/BusinessPartner.svg",
      ],
      [
        `Business purchase, merger and divestiture `,
        `Prepare and assess projections and support in your business acquisition and other strategic decisions.`,
        "assets/serviceicon/Businesspurchasemerger.svg",
      ],
      [
        `Structuring and due diligence`,
        `Assessment of project goals, financial analytics and business models for your business deals.`,
        "assets/serviceicon/Structuringandduediligence.svg",
      ],
    ],
    p2: `For additional information, please feel free to `,
    dot: true,
  };

  return (
    <div className="businesstax">
      <Helmet>
        <title>Business Consulting Service – ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          // content="To keep the complete employee’s financial transaction details, our payroll outsourcing keeps track of employees' salary, wages, overtime compensation, and fringe benefits."
        />
      </Helmet>
      <Subbanner
        img_desktop="assets/banners/EditBanner/12.jpg"
        main="Business Consulting Service – Strengthen Business Strategic Planning"
        desc="As your business partner, ClearStone Group aims to help strengthen your business with purchasing, selling, due diligence, and structuring requirements."
        btn=""
      />
      <Breadcrumb service="Business Consulting" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default BusinessConsulting;
