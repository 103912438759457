import React, { useEffect, useState } from "react";
import Aboutinner from "../components/Aboutinner";
import Blogcta from "../components/Blogcta";
import Certification from "../components/Certification";
import Subbanner from "../components/Subbanner";
import Cta from "../components/Cta";
import "./About.scss";
import { getBanner } from "../components/Blogdata";
import Businesstaxsec from "../components/Businesstaxsec";
import Mvsec from "../components/Mvsec";
import { Helmet } from "react-helmet";

function About() {
  // const [banner, setBanner] = useState(false);

  // useEffect(() => {
  //   getBanner("about").then((res) => {
  //     console.log(res.banner);
  //     setBanner(res.banner);
  //   });
  // }, []);

  const data = [
    "ClearStone Group is a leading provider of professional services in Australia. We provide a complete wide-range of services; including project management, engineering, construction management, environmental consulting, and more.",
    "  We have a team of highly experienced and qualified professionals, who are dedicated to providing the best possible service for our clients. We take pride in ourselves on our commitment to excellence and our ability to deliver enriched quality results.",
    " We are proud to have worked on some of the most iconic projects in Australia, and we continue to strive for effectively efficiency in everything we do.",
    "ClearStone Group is a company that you can certainly trust to provide quality services and deliver fast-paced results.",
    "<Link to={`/contact`}>Contact us</Link>  today to discuss your next project.",
  ];
  const data2 = {
    h1: "Values With A Strong Foundation",
    arr: [
      [
        `Assurance `,
        ` We always work in your best interests`,
        "assets/serviceicon/Accuratecalculationofwages.svg",
      ],
      [
        `Trust and Integrity`,
        ` #1 in our playbook.`,
        "assets/serviceicon/Reviewofcontractorandvendorstatus.svg",
      ],
      [
        `Partnership  `,
        ` Growth never stops when we work together.`,
        "assets/serviceicon/Reviewofpenaltyrates.svg",
      ],
      [
        `Contribute   `,
        ` We play our role in the community.`,
        "assets/serviceicon/Reviewofpenaltyrates.svg",
      ],
      [
        `Clear and Prompt   `,
        `  We get to the point, and we do it on time.`,
        "assets/serviceicon/Reviewofpenaltyrates.svg",
      ],
    ],
  };
  return (
    <div className="about">
      <Helmet>
        <title>About - ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          // content="To keep the complete employee’s financial transaction details, our payroll outsourcing keeps track of employees' salary, wages, overtime compensation, and fringe benefits."
        />
      </Helmet>

      <Subbanner
        img_desktop="assets/banners/5.webp"
        img="assets/5.webp"
        main="Welcome to ClearStone Group!"
        desc="We are a team of passionate individuals who work together to provide professional services that make a difference."
        btn=""
      />
      <Aboutinner data={data} />

      {/* <Mvsec data={data2} /> */}

      <Cta
        main="Request"
        sub="A Quote"
        desc="Get your corporate a beautifully customer engaging quote to attract the market now at ClearStone – the best tax consultancy company. "
        btn="Get a Quote"
        image="assets/services/ctabanner.webp"
      />
      <Certification
        sub="Our Certifications"
        main="Certified Public Accountants"
        desc="ClearStone Group – the best tax consultancy company take pride-in to state its strong relationship with the several corporates and firms with the help of market captive team of professionals. Here are the some of the public firms and corporates with whom we have provided our best services…"
        btn=""
      />
      <Blogcta />
    </div>
  );
}

export default About;
