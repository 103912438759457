import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./Businesstax.scss";
function Individualtaxation() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);
  const data = {
    h1: "Individual Taxation",
    p: `
         <p className='fp'>
         We review your prior returns and your current position to determine what is reasonable to deduct. We ensure you maximize your claims but ensure you are still compliance and are not in risk.
        </p>`,
    arr: [
      [
        `Tax planning  `,
        `Review of income and income generating source to help us determine deductions that can be claimed`,
        "assets/serviceicon/Marginaltaxratesapplicable.svg",
      ],

      [
        `Rental properties `,
        ` Review and completion of rental schedules along with appropriate deductions that can be claimed.`,
        "assets/serviceicon/assetsprotection.svg",
      ],

      [
        `Capital gains tax `,
        `Completion of CGT calculations and review of the events for CGT discounts / concessions.`,
        "assets/serviceicon/Individualtaxation.svg",
      ],
    ],
    // arr: [
    //     [`Marginal tax rates applicable to individuals `, ` Extra tax paid for every dollar made`, 'assets/serviceicon/Marginaltaxratesapplicable.svg'],

    //     [`Asset protection `, ` Financial strategy to shield assets from creditors`, 'assets/serviceicon/assetsprotection.svg'],

    //     [`Individual taxation of foreign income`, `30% withholding tax on certain US sourced income`, 'assets/serviceicon/Individualtaxation.svg'],

    //     [`Capital gains tax `, `A tax on the profit from selling a valuable asset`, 'assets/serviceicon/Capitalgainstax.svg'],

    //     [`Income tax planning and advice `, `Lowering income, increasing tax deductions, and using tax credits`, 'assets/serviceicon/Incometaxplanning.svg'],
    // ],
    p2p2: `  today to get a personal tax advisor to assist you with your individual returns.`,
    caps: true,
  };

  return (
    <div className="businesstax">
      <Helmet>
        <title>Individual Taxation Services - ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          content="Minimize your tax liability by acquiring our personal tax advisory services through the tactful strategies of trusts, superannuation, marginal individuals tax rates of individual and foreign income, and capital gain…"
        />
      </Helmet>

      <Subbanner
        img_desktop={"assets/banners/EditBanner/1-0.jpg"}
        main="Individual Taxation Services "
        desc="Know what you can and can’t deduct, we are here to help you minimize your taxable position at an individual level. We will review your position and determine what is available to you for what you can deduct to help reduce your liability. "
        btn=""
      />

      <Breadcrumb service="Individual Taxation" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default Individualtaxation;
