import React, { useEffect, useState } from "react";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./Businesstax.scss";
import { Helmet } from "react-helmet";
function FinancialConsulting() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);

  const data = {
    h1: "Financial Consulting",
    p: `
         <p className='fp'>
         Our financial consultants can help your business mitigate hazards by providing management insights into your business. As your finance business partner, we aim to help improve profitability and cash flow by designing a realistic and complete long-term investment plan. Our financial counselling services help you assess your finances, and identify the best investments.
        </p>`,
    arr: [
      // [`Financial diagnostic analysis`, `Financial review to identify pitfalls in processes and ensure success`, 'assets/serviceicon/Financialdiagnosticanalysis.svg'],

      [
        `Finance Business Partners & economic projections `,
        ` Provide financial statistics, predictions, and analytical reports for informed decision making and planning`,
        "assets/serviceicon/FinanceBusinessPartnerseconomicprojections.svg",
      ],

      [
        `Budgets & cash flow forecasts  `,
        `Cash budgeting and forecasting to assist with cash flow normalization and growth.`,
        "assets/serviceicon/Budgets&cashflowforecasts.svg",
      ],

      [
        `Improving Business Performance `,
        `Provide analysis of actual performance against forecast and providing solutions to improve business performance.`,
        "assets/serviceicon/ImprovingProfitability.svg",
      ],

      [
        `Financial consulting services`,
        `Provision of wealth management services and advisory services over your assets to ensure they are growing in line with a devised financial strategy.`,
        "assets/serviceicon/Financialconsultingservices.svg",
      ],
    ],
    p2: `For more of such growing business consulting services, `,
    dot: true,
  };

  return (
    <div className="businesstax">
      <Helmet>
        <title>Financial Consulting Service – ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          // content="To keep the complete employee’s financial transaction details our payroll outsourcing accommodates you to keep track of paycheck transactions related to salary, wages, overtime pay, and fringe benefits."
        />
      </Helmet>

      <Subbanner
        img_desktop="assets/banners/EditBanner/3.jpg"
        main="Financial Consulting Service – Improve Profitability & Forecasting "
        desc="Our expertise in financial consulting and economic projection models can help you with your budget tracking, cash flow planning and forecasting and by extension improve profitability."
        btn=""
      />

      <Breadcrumb service="Financial Consulting" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default FinancialConsulting;
