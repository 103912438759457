import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./css/Floatingnav.scss";
import { FaUserTie, FaAlipay, FaBitcoin } from "react-icons/fa";

function Floatingnav() {
  let [val, setVal] = useState(true);

  useEffect(() => {
    if (window.screen.width < 1200) {
      setVal(false);
    }
  }, []);

  return (
    <div className={val ? "floatingnav" : "floatingnav floatingnavhide"}>
      <div className="servicewrapper">
        <h1>Services</h1>
        <div className="line"></div>
        <ul className="services">
          <Link to="/business-advisory">
            <li>
              <img src="./assets/services/Bussines.png" /> BUSINESS ADVISORY
            </li>
          </Link>
          <Link to="/tax-advisory">
            <li>
              <img src="./assets/services/Tax.png" />
              TAX ADVISORY
            </li>
          </Link>

          <Link to="/bookkeeping-and-payroll-service">
            <li>
              <img src="./assets/services/Bookkeeping.png" />
              BOOKKEEPING AND PAYROLL SERVICE
            </li>
          </Link>
        </ul>
        <div
          onClick={() => {
            setVal(!val);
          }}
          className={val ? "toogle" : "toogle1"}
        >
          <p>{val ? "<" : ">"}</p>
        </div>
      </div>
    </div>
  );
}

export default Floatingnav;
