import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./css/Taxcalculator.scss";
function Taxcalculator() {
  let [aftertax, setAftertax] = useState(0);

  let [bef, setBef] = useState("");

  let [bef2, setBef2] = useState("");
  var currency = "USD";
  var options = {
    maximumFractionDigits: 0,
    currency: currency,
    style: "currency",
    currencyDisplay: "symbol",
  };

  const changedata = () => {

    let tef = bef.replace(",").replace("$");
    console.log('tef', tef)
    if (tef <= 18200) {
      console.log(`< 18200`)
      let matth = 0;
      setAftertax(
        matth || matth === 0
          ? localStringToNumber(matth).toLocaleString(undefined, options)
          : ""
      );
    } else if (tef >= 18201 && bef <= 45000) {
      console.log(`> 18201 && < 45000 `)
      let a = ((tef - 18201) * 0.16);
      let matth = Math.ceil(a);
      setAftertax(
        matth || matth === 0
          ? localStringToNumber(matth).toLocaleString(undefined, options)
          : ""
      );
    } else if (tef >= 45001 && tef <= 135000) {
      console.log(`> 45001 && < 135000 `)
      let a = ((tef - 45001) / 100) * 30 + 4288;
      let matth = Math.ceil(a);
      setAftertax(
        matth || matth === 0
          ? localStringToNumber(matth).toLocaleString(undefined, options)
          : ""
      );
    } else if (tef >= 135001 && tef <= 190000) {
      console.log(`> 135001 && < 190000 `)
      let a = ((tef - 135001) / 100) * 37 + 31288;
      let matth = Math.ceil(a);
      setAftertax(
        matth || matth === 0
          ? localStringToNumber(matth).toLocaleString(undefined, options)
          : ""
      );
    } else {
      console.log(`> 190001 `)
      let a = ((tef - 190001) / 100) * 45 + 51638;
      let matth = Math.ceil(a);
      setAftertax(
        matth || matth === 0
          ? localStringToNumber(matth).toLocaleString(undefined, options)
          : ""
      );
    }
  };
  function localStringToNumber(s) {
    return Number(String(s).replace(/[^0-9.-]+/g, ""));
  }

  const getdata = (e) => {
    let numbers = /^[0-9]+$/;

    let num = e.target.value;
    setBef(num.replace(",", "").replace("$", ""));
    // let coma = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    setBef2(
      num || num === 0
        ? localStringToNumber(num).toLocaleString(undefined, options)
        : ""
    );
    // console.log(num, coma)
  };

  return (
    <div className="taxcalculator">
      <div className="fst">
        <h1>Tax Calculator</h1>
        <div className="insc">
          <h3>Important Information:</h3>
          <p className="para1">
            Our simple tax calculator is updated every year in conjunction with
            the Australian Taxation Office's Pay As You Go (PAYG) schedules.
            Find out more about the{" "}
            <a
              href="https://www.ato.gov.au/rates/individual-income-tax-rates/"
              target={"_blank"}
            >
              {" "}
              ATO's
            </a>{" "}
            rates.
          </p>
          <p>
            Please be mindful that our tax calculations are only estimates. To
            find out what your final tax return summary will look like, call
            (02) 9758 2911 and let our tax accountants walk you through the tax
            refund process with ease.
            {/* Our office locator will help you navigate your nearest office and you can book an appointment online. */}
          </p>
          <p>
            For further information <Link className="anh" to={"/contact"}> contact us.</Link>
          </p>
        </div>
      </div>
      <div className="inputwrapper">
        <div className="wrap">
          <p className="da">
            Have quick and easy access to this tax calculator. The tax will not
            be calculated if your annual amount is less than 18,200.
          </p>

          <input
            type="text"
            value={bef2}
            onChange={getdata}
            className="textfields"
            placeholder="$0"
          />
        </div>
        <div className="wrap">
          <p>Your total Income Tax*</p>
          <input type="text" readOnly className="textfields" value={aftertax} />
          <p >The above rates do not include the Medicare levy of 2%.</p>
        </div>
        <button className="btn" onClick={changedata}>
          Calculate
        </button>
      </div>
    </div>
  );
}

export default Taxcalculator;
