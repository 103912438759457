import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import Quickform from "../components/Quickform";
import "./Businesstax.scss";
function Businesstax() {
  // const [banner, setBanner] = useState(false);

  // useEffect(() => {
  //   getBanner("service").then((res) => {
  //     setBanner(res.banner);
  //   });
  // }, []);

  const handleClick = (event) => {
    event.preventDefault();
  };

  const data = {
    h1: "Business Advisory",
    p: `
         <p className='fp'>
         If you are looking for business operations guidelines, assistance, and solutions; ClearStone Group's Business Advisory Service Line aims to provide the below services:
        </p>`,
    arr: [
      [
        `Virtual CFO `,
        ` Provision of detailed and accurate financial data and preparation of periodic management reports to provide detailed insights on company performance.`,
        "assets/serviceicon/VirtualCFO.svg",
      ],
      [
        `Business Compliance `,
        `Ensuring that the business processes are compliant with taxation and regulatory rules.`,
        "assets/serviceicon/BusinessCompliance.svg",
      ],
      [
        `Budgets & cash flow forecasts `,
        `Preparation of budgets and cash flow forecasts to assist with tracking of company performance and setting corporate goals.`,
        "assets/serviceicon/Budgets&cashflowforecasts.svg",
      ],

      [
        `Purchase and sale of businesses `,
        `Provision of financial projections and support with due diligence and valuations.`,
        "assets/serviceicon/Purchaseandsaleofbusinesses.svg",
      ],
    ],
    p2: `Don't hesitate to `,
    p2p2: "for more information on our complete Business Advisory Services, and how we can assist you in growing your business.",
  };

  return (
    <div className="businesstax">
      <Helmet>
        <title>Business Advisory Services – Help Your Business Grow</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          // content="To keep the complete employee’s financial transaction details, our payroll outsourcing keeps track of employees' salary, wages, overtime compensation, and fringe benefits."
        />
      </Helmet>
      <Subbanner
        img_desktop="assets/banners/banners/sub.png"
        img_mobile="assets/banners/banners/mobilebanner/business.jpg"
        main="Business Advisory Services – Help Your Business Grow"
        desc="Grow your business with our business and financial consultancy which includes comprehensive and customized advisory services."
        btn=""
      />

      <Breadcrumb service="Business Advisory" />

      <Businesstaxsec data={data} />

      {/* <Quickform /> */}
    </div>
  );
}

export default Businesstax;
