import Calendar from "react-calendar";
import "./Contact.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { FaRegCalendarAlt, FaCalendarAlt } from "react-icons/fa";
import { useRef } from "react";
import { Helmet } from "react-helmet";

function Contact() {
  const [isSubmit, setIsSubmit] = useState(false);
  const dateInput = useRef();

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ shouldUseNativeValidation: true });
  const initialVailue = {
    fname: "",
    lname: "",
    email: "",
    number: "",
    company: "",
    query: "",
    date: "",
    service: "Business Consulting",
    location: "Castle Hill",
    client: "A New Client",
  };

  // const url = "https://hutaib.admin.simboz.com/ProductsAPI/contact";
  const url = "https://admin.clearstone.simboz.com/api/contact-form";

  const [formData, setFormData] = useState(initialVailue);

  const getData = (e) => {
    e.preventDefault();
    // console.log(e.target.name, e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const formRef = useRef(null);

  const onSubmit = (data) => {
    setError(
      "test",
      { type: "focus", message: "Double Check This" },
      { shouldFocus: true }
    );

    const {
      fname,
      lname,
      email,
      number,
      company,
      query,
      date,
      service,
      location,
      client,
    } = formData;
    if (fname && lname && email && number && company && query && date) {

      // console.log(fname, "fname");
      // console.log(lname, "lname");
      // console.log(email, "email");
      // console.log(number, "number");
      // console.log(company, "company");
      // console.log(query, "query");
      // console.log(date, "date");
      // console.log(service, "service");
      // console.log(location, "location");
      // console.log(client, "client");

      const formaData = new FormData();

      formaData.append("fname", fname);
      formaData.append("lname", lname);
      formaData.append("email", email);
      formaData.append("number", number);
      formaData.append("appoinemt_date", date);
      formaData.append("service", service);
      formaData.append("client", client);
      formaData.append("address", location);
      formaData.append("company", company);
      formaData.append("query", query);

      axios
        .post(url, formaData)
        .then((res) => {
          Swal.fire(
            "Query Sent Successfully",
            "Thank you for contacting Us, One of our representative will get back to you shortly"
          );
          setIsSubmit(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      Swal.fire("Error", "Empty Field Found", "error");
    }
  };

  const varifyCallback = (e) => {
    alert(e);
    console.log(e);
  };
  const [value, setValue] = useState("");
  const [show, setShow] = useState(false);

  const SelectDate = (e) => {
    let val = e.toLocaleString().split(",")[0];
    let formateDate = val.split("/").join("-");
    console.log(formateDate);

    setValue(val);
    setFormData({ ...formData, date: val });
  };
  const HandleDayDisable = (e) => {
    return e.date.getTime() > new Date().getTime() ? false : true;
  };
  // console.log(formData);
  return (
    <div className="contactus_formcont">
      <Helmet>
        <title>Contact - ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
        // content="To keep the complete employee’s financial transaction details, our payroll outsourcing keeps track of employees' salary, wages, overtime compensation, and fringe benefits."
        />
      </Helmet>
      <div className="contactforminner">
        <div className="contactinnerleft">
          <div className="subh">
            <p className="line"></p>
            <p>For Further Information</p>
          </div>
          <p className="contacthedi">
            We'd love to hear from <span className="contactyou">you!</span>
          </p>
          <div className="iconwraper">
            <div className="contactinnerinfo">
              <div className="iconsec">
                <span className="icon">
                  <MdLocationOn />
                </span>
                <div className="data">
                  <p>
                    <span className="extradata">Office address :</span>
                    <a
                      href="https://goo.gl/maps/Qze8YWpq6MZCbHSd7"
                      target="_blank"
                    >
                      Address : 23 5-7 Anella Ave, Castle Hill, NSW 2154{" "}
                    </a>{" "}
                    <br />
                    <a
                      href="https://goo.gl/maps/4SRyfCT1iJv9UyWP8"
                      target="_blank"
                    >
                      Address : 1/108 Haldon St, Lakemba, NSW 2195
                    </a>
                    <br />
                    <a
                      href="https://maps.app.goo.gl/StLk3ZxaSaYz2BDV8"
                      target="_blank"
                    >
                      Address : 10/1A, Wongala Crescent, Beecroft, NSW 2119
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="contactinnerinfo">
              <div className="iconsec">
                <span className="icon">
                  <MdEmail />
                </span>
                <div className="data">
                  <p>
                    <a href="mailto:info@clearstonegroup.com.au">
                      <span className="extradata">Email :</span>{" "}
                      info@clearstonegroup.com.au
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="contactinnerinfo">
              <div className="iconsec">
                <span className="icon">
                  <MdPhone />
                </span>
                <div className="data">
                  <p>
                    <a href="tel:(02) 9758 2911">
                      {" "}
                      <span className="extradata">Contact :</span> (02) 9758
                      2911
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <div className="contactinnerright">
            <div className="ali">
              <div className="formfields">
                <div className="tfs">
                  <div className="formfield">
                    <p>First Name :</p>
                    <input
                      required
                      type="text"
                      // name="name"
                      onKeyUp={getData}
                      {...register("fname", {
                        required: "First name is Required",
                      })}
                    />
                  </div>
                  <div className="formfield">
                    <p>Last Name :</p>
                    <input
                      required
                      type="text"
                      // name="name"
                      onKeyUp={getData}
                      {...register("lname", {
                        required: "Last is Required",
                      })}
                    />
                  </div>
                </div>
                <div className="tfs">
                  <div className="formfield">
                    <p>Email :</p>
                    <input
                      required
                      type="email"
                      onKeyUp={getData}
                      {...register("email", {
                        required: "email is Required",
                        pattern: /^\S+@\S+$/i,
                      })}
                    />
                  </div>
                  <div className="formfield">
                    <div className="spbt">
                      <span>Appointment date :</span>
                      <span onClick={() => setShow(!show)}>
                        <FaCalendarAlt className="calander" />
                      </span>
                      <Calendar
                        onClickDay={() => setShow(!show)}
                        className={show ? "sohw" : "hidecal"}
                        onChange={SelectDate}
                        tileDisabled={HandleDayDisable}
                      />
                    </div>
                    <input
                      defaultValue={value}
                      ref={dateInput}
                      name="date"
                      required
                      disabled
                      placeholder="mm-dd-yy"
                      type="text"
                    />
                  </div>
                </div>
                <div className="tfs">
                  <div className="formfield">
                    <p>Contact Number :</p>
                    <input
                      required
                      type="text"
                      // name="number"
                      onKeyUp={getData}
                      {...register("number", {
                        required: "number is Required",
                        maxLength: 20,
                        pattern: {
                          value: /^\+?\d+/i,
                          message: "error message",
                        },
                      })}
                    />
                  </div>
                  <div className="formfield">
                    <p>Service :</p>
                    <select
                      id="service"
                      name="service"
                      title="Product"
                      required
                      value={formData.service}
                      onChange={(e) =>
                        setFormData({ ...formData, service: e.target.value })
                      }
                    >
                      <option value="Business Consulting">
                        Business Consulting
                      </option>
                      <option value="Financial Consulting">
                        Financial Consulting
                      </option>
                      <option value="Business Taxation">
                        Business Taxation
                      </option>
                      <option value="Individual Taxation">
                        Individual Taxation
                      </option>
                      <option value="Bookkeeping">Bookkeeping</option>
                      <option value="Payroll and others">
                        Payroll and others
                      </option>
                    </select>
                  </div>
                </div>

                <div className="tfs">
                  <div className="formfield">
                    <p>Are you:</p>
                    <div className="flex">
                      <span className="radio">
                        <input
                          type="radio"
                          id="New"
                          checked
                          name="client"
                          value="A New Client"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              client: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="html">A New Client</label>
                      </span>
                      <span className="radio">
                        <input
                          type="radio"
                          id="Existing"
                          name="client"
                          value="An Existing Client"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              client: e.target.value,
                            });
                          }}
                        />
                        <label htmlFor="css">An Existing Client</label>
                      </span>
                    </div>
                  </div>

                  <div className="formfield">
                    <p>Address :</p>
                    <select
                      id="service"
                      name="address"
                      title="Product"
                      required
                      value={formData.location}
                      onChange={(e) =>
                        setFormData({ ...formData, location: e.target.value })
                      }
                    >
                      <option value="Castle Hill">Castle Hill</option>
                      <option value="Lakemba NSW">Lakemba</option>
                      <option value="Beecroft NSW">Beecroft</option>
                    </select>
                  </div>
                </div>

                <div className="formfield">
                  <p>Business Name :</p>
                  <input
                    required
                    type="text"
                    // name="company"
                    onKeyUp={getData}
                    {...register("company", {
                      required: "company is Required",
                      // maxLength: 20,
                    })}
                  />
                </div>
                <div className="textareasec">
                  <div className="textarea">
                    <p className="txt">Query :</p>
                    <textarea
                      style={{ resize: "none", boxSizing: "border-box" }}
                      required
                      type="text"
                      // name="query"
                      onKeyUp={getData}
                      {...register("query", {
                        // required: "query is Required",
                      })}
                    />
                  </div>
                </div>
              </div>

              {/* <ReCAPTCHA
                sitekey="6LeUK_wfAAAAAGE6NqKc2S6MyENYecFF4DSyPOUC"
                data-theme="dark"
                onKeyUp={varifyCallback}
              /> */}
              <button className="contactbtn">Send message</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
