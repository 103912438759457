import React from "react";
import "./css/Breadcrumb.scss";
import { Link } from "react-router-dom";

function Breadcrumb({ service }) {
  return (
    <div className="breadcrumb">
      <div className="breadcrumbinner">
        <p>
          <Link to={"/"}>Home</Link>
          {service == "Business Consulting" ? (
            <>
              <span>&gt;</span>{" "}
              <Link to={"/business-advisory"}>Business Advisory</Link>
            </>
          ) : service == "Financial Consulting" ? (
            <>
              <span>&gt;</span>
              <Link to={"/business-advisory"}>Business Advisory</Link>{" "}
            </>
          ) : service == "Business Taxation" ? (
            <>
              <span>&gt;</span>
              <Link to={"/tax-advisory"}>Tax Advisory</Link>{" "}
            </>
          ) : service == "Individual Taxation" ? (
            <>
              <span>&gt;</span>
              <Link to={"/tax-advisory"}>Tax Advisory</Link>{" "}
            </>
          ) : service == "Bookkeeping Service" ? (
            <>
              <span>&gt;</span>
              <Link to={"/bookkeeping-and-payroll-service"}>
                Bookkeeping And Payroll Service
              </Link>{" "}
            </>
          ) : service == "Payroll Services" ? (
            <>
              <span>&gt;</span>
              <Link to={"/bookkeeping-and-payroll-service"}>
                Bookkeeping And Payroll Service
              </Link>
            </>
          ) : null}
          <span>&gt;</span> {service}{" "}
          {console.log(service)}
        </p>
      </div>
    </div>
  );
}

export default Breadcrumb;
