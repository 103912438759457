import React, { useEffect, useState } from "react";
import "./css/Businesstaxsec.scss";
import Floatingnav from "./Floatingnav";
import { Link, useNavigate } from "react-router-dom";

function Businesstaxsec({ data }) {
  function createMarkup(abcd) {
    // console.log(abcd)
    return {
      __html: abcd,
    };
  }


  const handleClick = (event) => {
    event.preventDefault();
  };
  return (
    <div className="Businesstaxsec">
      <div className="Businesstaxsecinner">
        <h1 className="h1">{data.h1}</h1>
        <div className="line"></div>
        {/* {console.log(window.location.href.split('/'))} */}

        {window.location.href.split("/").at(-1) == "business-advisory" ? (
          <p className="fp">
            Business Advisory Services involves two primary strategies:{" "}
            <Link className="link" to={"/business-consulting"}>
              {" "}
              business consulting{" "}
            </Link>{" "}
            and{" "}
            <Link className="link" to={"/financial-consulting"}>
              financial consulting.
            </Link>
          </p>
        ) : window.location.href.split("/").at(-1) == "tax-advisory" ? (
          <p className="fp">
            Tax Advisory Services is split into two service offerings:{" "}
            <Link className="link" to={"/business-taxation"}>
              {" "}
              Business Taxation{" "}
            </Link>{" "}
            and{" "}
            <Link className="link" to={"/individual-taxation"}>
              Individual Taxation
            </Link>
            .
          </p>
        ) : window.location.href.split("/").at(-1) ==
          "bookkeeping-and-payroll-service" ? (
          <p className="fp">
            We provide services to take care of both:{" "}
            <Link to={"/bookkeeping-service"}> Bookkeeping Services </Link> and{" "}
            <Link to={"/payroll-services"}> Payroll Services </Link> for your
            business.
          </p>
        ) : null}
        <div dangerouslySetInnerHTML={createMarkup(data.p)}></div>

        <div className="row">
          {data.arr.map((item, index) => {
            return (
              <div className="cols" key={index}>
                <div className="sec">
                  <div className="fb">
                    {item[2] ? <img src={item[2]} /> : null}
                    <p> {item[0]}</p>
                  </div>
                  <span>{item[1]}</span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="dflex">
          <p
            className="fp2"
            dangerouslySetInnerHTML={createMarkup(data.p2)}
          ></p>
          <p style={{ marginRight: "5px" }}>
            <Link to="/contact">{data.caps ? " Contact " : " contact "}</Link>
            us
            {data.dot ? "." : ""}
          </p>
          <p
            className="fp2"
            dangerouslySetInnerHTML={createMarkup(data.p2p2)}
          ></p>
        </div>
      </div>
      <Floatingnav />
    </div>
  );
}

export default Businesstaxsec;
