import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { getBanner } from "../components/Blogdata";
import Breadcrumb from "../components/Breadcrumb";
import Businesstaxsec from "../components/Businesstaxsec";
import Subbanner from "../components/Subbanner";
import "./Businesstax.scss";
function Bookkeeping() {
  const [banner, setBanner] = useState(false);

  useEffect(() => {
    getBanner("service").then((res) => {
      console.log(res.banner);
      setBanner(res.banner);
    });
  }, []);
  const data = {
    h1: "Bookkeeping And Payroll Service",
    p: `
         <p className='fp'>
         Accurate data and bookkeeping are the source of strong insights and management reporting and decision making. We provide bookkeeping services to ensure that you are compliant and that the ledger is airtight.
        </p>
        <p className='fp'>
        Our services can also extend to payroll and other accounts functions. Our team will review employment contracts for accurate employee set up and payments, taxation of gross wages, superannuation, leave accruals and bookings, payroll tax and employment termination payments. Our team of experienced accountants also manages Accounts Payable and Accounts Receivable functions.
       </p>
        `,

    arr: [
      [
        `Accurate calculation of wages  `,
        `We base our calculations off signed employment contracts to ensure that the employees are getting exactly what they are entitled to.`,
        "assets/serviceicon/Accuratecalculationofwages.svg",
      ],

      [
        `Payroll issue `,
        ` we assist with any payroll disputes to help resolve any queries and/or issues.`,
        "assets/serviceicon/Payrollissue.svg",
      ],

      [
        `Compliance & regulatory engagements `,
        `we ensure that your business is making timely payments for your employees which includes withholding tax and superannuation.`,
        "assets/serviceicon/Compliance&regulatoryengagements.svg",
      ],

      //   [
      //     `Review of Payroll tax `,
      //     `A tax on wages and salaries to fund Social Security, Medicare, and unemployment insurance, and unemployment insurance`,
      //     "assets/serviceicon/ReviewofPayrolltax.svg",
      //   ],
      [
        `Accounts Payable and Receivable `,
        `We will ensure that all your invoices are recorded and bills paid on time.`,
        "assets/serviceicon/ReviewofPayrolltax.svg",
      ],
    ],

    p2: `We ensure effective communication guaranteeing quality professional advice for outsourcing using the right tools.`,
    p2p2: " now to get a boost in your bookkeeping and payroll services.",
    caps: true,
  };
  return (
    <div className="businesstax">
      <Helmet>
        <title>Bookkeeping And Payroll Service – ClearStone Group</title>
        <meta name="description" content="App Description" />
        <meta
          data-react-helmet="true"
          name="description"
          content="To keep the complete employee’s financial transaction details, our payroll outsourcing keeps track of employees' salary, wages, overtime compensation, and fringe benefits."
        />
      </Helmet>

      <Subbanner
        img_desktop={
          window.screen.width > 760
            ? "assets/banners/banners/sub4.png"
            : "assets/banners/2a.jpg"
        }
        main="Bookkeeping And Payroll Service – Grow Your Company With Outsourcing"
        desc="Our accounting and bookkeeping services alleviates the time and pressure from you to keep up to date with your financial statements."
        btn=""
      />

      <Breadcrumb service="Bookkeeping And Payroll Service" />

      <Businesstaxsec data={data} />
    </div>
  );
}

export default Bookkeeping;
