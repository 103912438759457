import React from "react";
import "./css/Red_flags.scss";
import Processheading from "./Processheading";
function Red_flags() {
  return (
    <div className="Red_flags" id="Red_flags">
      <Processheading pnum="2" heading=" Solve The Red Flags" />
      <div className="innerFinancialhealth">
        <div className="spsec">
          <p>
            <span>•</span> Have you ever lived in a house with a leaky tap that
            doesn 't turn off completely?
          </p>
          <p>
            <span>•</span>What seems to only be a small consistent drip to the
            naked eye.
          </p>
          <p>
            <span>•</span> In just a few short years, can end up being thousands
            of litres of water wasted down the drain.
          </p>
          <p>
            <span>•</span> This is what tends to happen with Medical
            Professionals finances.
          </p>
          <p>
            <span>•</span> They are so busy working long hours and caring for
            their patients, that they don 't even realise that o finances
            represent a leaking tap.
          </p>
          <p>
            <span>•</span> That' s why, after analysing your current financial
            health.
          </p>
          <p>
            <span>•</span> The next step is to deal with what we call the 'Red
            Flags '.
          </p>
          <p>
            <span>•</span>These are pesky leaks in your finances that need
            addressing before we go on to systemizing your bookkeeping.
          </p>
          <p>
            <span>•</span>In most cases, our clients aren 't even aware of these
            issues, but once we add them up, they account for tens of thousands
            of dollars lost down the drain.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Red_flags;
