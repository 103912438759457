import React, { useEffect } from 'react'
import './Blogdetail.scss'
import { BlogDetail } from '../components/Blogdata'
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import Subbanner from '../components/Subbanner'
import { useState } from 'react';
function Blogdetail() {
    let slug = window.location.href.split("/").pop();
    const [detail, setDetail] = useState(false);
    const [recents, setRecents] = useState([]);


    useEffect(() => {
        BlogDetail(slug).then((res) => {
            console.log(res);

            setDetail(res.blog);
            setRecents(res.recent);
            console.log(detail, 'detail')
        })


    }, [])


    const createMarkup = (pro) => {
        return { __html: pro };
    }



    return (
        <div className='Singleblog'>

            {/* <Subbanner img_desktop={detail.desktop_banner} img_mob={detail.mob_banner} main='Our Blogs' desc='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.' btn='' /> */}
            <div className='heading'>
                <h1 >{detail?.title}</h1>
                <div className='line'></div>
            </div>
            <div className='singlebloglistinner'>
                <div className='leftcol'>

                    <div dangerouslySetInnerHTML={createMarkup(detail?.content)}></div>

                </div>
                <div className='rightcol'>
                    <div className='firstcol'>
                        <h1>Recent Blogs</h1>
                        {recents.map((item, index) => {

                            return (
                                <div className='mapdata' key={index}>
                                    <img src={item?.thumbnail} />
                                    <p>{item?.title}</p>
                                </div>
                            )
                        }

                        )}

                    </div>
                    <div className='thirdcol' style={{ backgroundImage: `url(assets/1.jpg)` }}>
                        <div className='thirdinner'>
                            <h1>Have Any Question?</h1>
                            <p>Examine keenly both differences “Custom Website vs. WordPress Website.”</p>
                            <p className='flexi'><FaPhoneAlt className='icons' /><a href='tel:(02) 9758 2911'>Phone : (02) 9758 2911</a> </p>
                            <p className='flexi'><FaEnvelope className='icons' /><a href='mailto:info@clearstonegroup.com.au'>Email : info@clearstonegroup.com.au</a></p>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
}

export default Blogdetail
